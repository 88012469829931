"use client";
import React, { useEffect, useState } from "react";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import { CustomDropdownOption } from ".";
import {
  NEW_INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { ArrowDownPlaneEnds } from "../../../../public/Theme";
import { ECOM_SELECT_TEXT, SHORTEN_WORDS } from "./constants";
import {
  INPUT_CONTAINER,
  LABEL,
  DROPDOWN_CARROT_CLASS,
  DROPDOWN_OPTIONS_CONTAINER,
  NEW_DROPDOWN_OPTIONS_INNER_CONTAINER,
  FIELD_TEXT_CLASS,
  RELATIVE_CLASS,
  DROPDOWN_OPTIONS_CLOSE,
  DROPDOWN_OPTIONS_OPEN,
  CARROT_CLASS,
  CARROT_ROTATE,
} from "./styles";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const NewCustomDropdownField = ({
  disabled,
  label,
  options,
  showIcons,
  setValue,
  field,
  value,
  errors,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(SHORTEN_WORDS(value));

  const inputFieldClass = errors?.[field]?.message
    ? INPUT_FIELD_ERROR
    : NEW_INPUT_FIELD_CLASS;

  const onBlurHandle = (e) => {
    if (!e?.currentTarget?.contains(e?.relatedTarget)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    setSelectedValue(SHORTEN_WORDS(value));
  }, [value]);
  return (
    <div
      className={disabled ? "opacity-40" : ""}
      onBlur={(e) => onBlurHandle(e)}
    >
      <p className={LABEL}>{label}</p>
      <div
        className={inputFieldClass + INPUT_CONTAINER + RELATIVE_CLASS}
        tabIndex={0}
        onClick={() => !disabled && setShowDropdown(!showDropdown)}
      >
        <p className=" text-[13px] ">{selectedValue || ECOM_SELECT_TEXT}</p>
        <div className={DROPDOWN_CARROT_CLASS}>
          <img
            className={CARROT_CLASS + (showDropdown ? CARROT_ROTATE : "")}
            src={ArrowDownPlaneEnds}
          />
        </div>
      </div>
      {
        <div
          className={
            DROPDOWN_OPTIONS_CONTAINER +
            (showDropdown ? DROPDOWN_OPTIONS_OPEN : DROPDOWN_OPTIONS_CLOSE)
          }
          tabIndex={0}
        >
          {showDropdown && (
            <div className={NEW_DROPDOWN_OPTIONS_INNER_CONTAINER}>
              {options?.map((item, index) => {
                return (
                  <CustomDropdownOption
                    field={field}
                    image={item?.media}
                    key={index}
                    setSelectedValue={setSelectedValue}
                    selectedValue={selectedValue}
                    setValue={setValue}
                    setShowDropdown={setShowDropdown}
                    showIcons={showIcons}
                    value={item?.title || item?.label}
                    varient={"black"}
                  />
                );
              })}
            </div>
          )}
        </div>
      }
      <ErrorTextWithIcon showIcon={true} text={errors?.[field]?.message} />
    </div>
  );
};

export default NewCustomDropdownField;
