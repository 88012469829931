import React from "react";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import { getSelectText } from "./constants";
import { INPUT_CONTAINER, LABEL } from "./styles";

const FieldArrayDropdown = ({
  errorFieldName,
  label,
  field,
  register,
  options,
  locale,
  values,
  destructureFieldName,
}) => {
  const inputClassName = errorFieldName?.[destructureFieldName]
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;
  const option = (item, index) => {
    return values ? (
      <option value={values?.[index]} key={index}>
        {item}
      </option>
    ) : (
      <option value={item} key={index}>
        {item}
      </option>
    );
  };

  return (
    <div className={INPUT_CONTAINER}>
      <p className={LABEL}>{label}</p>
      <div>
        <select name={field} {...register(field)} className={inputClassName}>
          <option disabled value="">
            {getSelectText(locale)}
          </option>
          {options?.map((item, index) => (item ? option(item, index) : null))}
        </select>
        <InputErrorMessage
          errorMessage={errorFieldName?.[destructureFieldName]?.message}
        />
      </div>
    </div>
  );
};

export default FieldArrayDropdown;
