"use client";
import React, { useEffect, useState } from "react";
import TypographyMontserrat from "../../TypographyMontserrat";
import { CustomDropdownOption } from ".";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { DropdownArrowDown, DropdownArrowUp } from "../../../../public/Theme";
import { SELECT_TEXT, SHORTEN_WORDS } from "./constants";
import {
  INPUT_CONTAINER,
  LABEL,
  DROPDOWN_CARROT_CLASS,
  DROPDOWN_OPTIONS_CONTAINER,
  DROPDOWN_OPTIONS_INNER_CONTAINER,
  FIELD_TEXT_CLASS,
  RELATIVE_CLASS,
  GROUP_HEADING_CONTAINER,
} from "./styles";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const CustomGroupedOptionsDropdown = ({
  label,
  options,
  setButtonDisabled,
  groups,
  showIcons,
  setValue,
  field,
  value,
  errors,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(SHORTEN_WORDS(value));

  const inputFieldClass = errors?.[field]?.message
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;

  const onBlurHandle = (e) => {
    if (!e?.currentTarget?.contains(e?.relatedTarget)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    setSelectedValue(SHORTEN_WORDS(value));
  }, [value]);
  return (
    <div onBlur={(e) => onBlurHandle(e)}>
      <p className={LABEL}>{label}</p>
      <div
        className={inputFieldClass + INPUT_CONTAINER + RELATIVE_CLASS}
        tabIndex={0}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <p className={FIELD_TEXT_CLASS}>{selectedValue || SELECT_TEXT}</p>
        <div className={DROPDOWN_CARROT_CLASS}>
          {showDropdown ? (
            <img src={DropdownArrowUp} />
          ) : (
            <img src={DropdownArrowDown} />
          )}
        </div>
      </div>
      {showDropdown && (
        <div className={DROPDOWN_OPTIONS_CONTAINER} tabIndex={0}>
          <div className={DROPDOWN_OPTIONS_INNER_CONTAINER}>
            {groups?.map((group, index) => {
              return (
                <div key={index}>
                  <div className={GROUP_HEADING_CONTAINER}>
                    <TypographyMontserrat
                      color={"Stroke_Grey"}
                      fontWeight={"medium"}
                      size={"xs"}
                      text={group}
                    />
                  </div>
                  {options
                    ?.filter((item) => {
                      return item?.title === group;
                    })
                    ?.map((item, index) => {
                      let values = Array?.isArray(item?.values)
                        ? item?.values
                        : [item?.values];
                      return values?.map((value, index) => {
                        return (
                          <CustomDropdownOption
                            field={field}
                            image={item?.media}
                            key={index}
                            setShowDropdown={setShowDropdown}
                            setSelectedValue={setSelectedValue}
                            setValue={setValue}
                            showIcons={showIcons}
                            value={value}
                            setButtonDisabled={setButtonDisabled}
                          />
                        );
                      });
                    })}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <ErrorTextWithIcon showIcon text={errors?.[field]?.message} />
    </div>
  );
};

export default CustomGroupedOptionsDropdown;
